// src/pages/elwandle/FormCreationDashboard.js

import React, { useState, useEffect } from 'react';
import './FormCreationDashboard.css';
import { openIndexedDB } from '../../utils/indexedDB'; // Import the shared IndexedDB utility

async function saveFormToIndexedDB(form) {
    const db = await openIndexedDB();
    const tx = db.transaction('forms', 'readwrite');
    const store = tx.objectStore('forms');
    const request = store.put(form); // Use put to add or update forms

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            resolve();
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

async function deleteFormFromIndexedDB(formId) {
    const db = await openIndexedDB();
    const tx = db.transaction('forms', 'readwrite');
    const store = tx.objectStore('forms');
    const request = store.delete(formId);

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            resolve();
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

async function getFormsFromIndexedDB() {
    const db = await openIndexedDB();
    const tx = db.transaction('forms', 'readonly');
    const store = tx.objectStore('forms');
    const request = store.getAll();

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            resolve(request.result);
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

async function saveDropdownOptionsToIndexedDB(fieldId, options) {
    const db = await openIndexedDB();
    const tx = db.transaction('dropdownOptions', 'readwrite');
    const store = tx.objectStore('dropdownOptions');
    const request = store.put({ fieldId, options });

    return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
    });
}

// Call this function for each dropdown field
async function processDropdownFields(fields) {
    for (const field of fields) {
        if (field.field_type === 'dropdown' && field.options_category_id) {
            try {
                const options = await fetch(`/api/assets/dropdown_options?options_category_id=${field.options_category_id}`)
                    .then(res => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.json();
                    });

                if (options && options.length > 0) {
                    await saveDropdownOptionsToIndexedDB(field.id, options);
                    console.log(`Dropdown options for field ${field.id} saved.`);
                } else {
                    console.warn(`No options available for dropdown field ${field.id}.`);
                }
            } catch (error) {
                console.error(`Error fetching options for field ${field.id}:`, error);
            }
        }
    }
}

function FormCreationDashboard({ onClose, user, organization }) {
    const [formName, setFormName] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [availableFields, setAvailableFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [fieldGroups, setFieldGroups] = useState({});
    const [existingForms, setExistingForms] = useState([]);
    const [editingForm, setEditingForm] = useState(null);
    const [viewingForm, setViewingForm] = useState(null);

    useEffect(() => {
        // Fetch categories for the organization
        fetch(`/api/assets/categories?organization=${encodeURIComponent(organization)}`)
            .then((res) => res.json())
            .then((data) => setCategories(data))
            .catch((err) => console.error(err));
    }, [organization]);

    useEffect(() => {
        // Fetch existing forms from IndexedDB
        const fetchForms = async () => {
            try {
                const forms = await getFormsFromIndexedDB();
                const orgForms = forms.filter(form => form.organization === organization);
                setExistingForms(orgForms);
            } catch (error) {
                console.error('Error fetching forms from IndexedDB:', error);
            }
        };
        fetchForms();
    }, [organization]);

    useEffect(() => {
        if (selectedCategory) {
            // Fetch fields from all field tables
            Promise.all([
                fetch(`/api/assets/fields?category_id=${selectedCategory}`).then(res => res.json()),
                fetch(`/api/assets/maintenance_fields?category_id=${selectedCategory}`).then(res => res.json()),
                fetch(`/api/assets/status_fields?category_id=${selectedCategory}`).then(res => res.json()),
                fetch(`/api/assets/update_fields?category_id=${selectedCategory}`).then(res => res.json())
            ])
                .then(async ([fields, maintenanceFields, statusFields, updateFields]) => {
                    // Add a sourceTable property to each field
                    const allFields = [
                        ...fields.map(f => ({ ...f, sourceTable: 'Asset Fields' })),
                        ...maintenanceFields.map(f => ({ ...f, sourceTable: 'Maintenance Fields' })),
                        ...statusFields.map(f => ({ ...f, sourceTable: 'Status Fields' })),
                        ...updateFields.map(f => ({ ...f, sourceTable: 'Update Fields' })),
                    ];

                    // Save dropdown options for offline use
                    await processDropdownFields(allFields);

                    // Group fields by sourceTable
                    const groupedFields = allFields.reduce((groups, field) => {
                        const group = groups[field.sourceTable] || [];
                        group.push(field);
                        groups[field.sourceTable] = group;
                        return groups;
                    }, {});
                    setAvailableFields(allFields);
                    setFieldGroups(groupedFields);
                })
                .catch(err => console.error(err));
        } else {
            setAvailableFields([]);
            setFieldGroups({});
        }
    }, [selectedCategory]);

    const handleFieldSelection = (fieldId) => {
        if (selectedFields.includes(fieldId)) {
            // Deselect the field
            setSelectedFields(selectedFields.filter(id => id !== fieldId));
        } else {
            // Select the field
            setSelectedFields([...selectedFields, fieldId]);
        }
    };

    const handleSelectAll = (sourceTable) => {
        const fieldsInGroup = fieldGroups[sourceTable].map(field => field.id);
        const allSelected = fieldsInGroup.every(fieldId => selectedFields.includes(fieldId));

        if (allSelected) {
            // Deselect all in group
            setSelectedFields(selectedFields.filter(id => !fieldsInGroup.includes(id)));
        } else {
            // Select all in group
            setSelectedFields([...new Set([...selectedFields, ...fieldsInGroup])]);
        }
    };

    const handleSaveForm = async () => {
        if (formName && selectedCategory && selectedFields.length > 0) {
            // Get the field definitions for the selected fields
            const selectedFieldDefinitions = availableFields.filter(field => selectedFields.includes(field.id));
            const category = categories.find(cat => cat.id === selectedCategory);
            const form = {
                formName,
                organization,
                categoryId: selectedCategory,
                categoryName: category ? category.name : '',
                fields: selectedFieldDefinitions,
                createdBy: user.username,
                createdAt: new Date()
            };

            // Include 'id' only when editing an existing form
            if (editingForm && editingForm.id !== undefined && editingForm.id !== null) {
                form.id = editingForm.id;
            }

            try {
                await saveFormToIndexedDB(form);
                alert('Form saved offline.');
                // Reset the form
                setFormName('');
                setSelectedFields([]);
                setSelectedCategory('');
                setAvailableFields([]);
                setFieldGroups({});
                setEditingForm(null);
                // Refresh existing forms
                const forms = await getFormsFromIndexedDB();
                const orgForms = forms.filter(f => f.organization === organization);
                setExistingForms(orgForms);
            } catch (error) {
                console.error('Error saving form to IndexedDB:', error);
                alert('Failed to save form.');
            }
        } else {
            alert('Please provide a form name, select a category, and select at least one field.');
        }
    };

    const handleDeleteForm = async (formId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete this form?`);
        if (confirmDelete) {
            try {
                await deleteFormFromIndexedDB(formId);
                alert('Form deleted.');
                // Refresh existing forms
                setExistingForms(existingForms.filter(form => form.id !== formId));
            } catch (error) {
                console.error('Error deleting form from IndexedDB:', error);
                alert('Failed to delete form.');
            }
        }
    };

    const handleEditForm = (form) => {
        setEditingForm(form);
        setFormName(form.formName);
        setSelectedCategory(form.categoryId);
        setSelectedFields(form.fields.map(field => field.id));
    };

    const handleViewForm = (form) => {
        setViewingForm(form);
    };

    const handleCancelEdit = () => {
        // Reset the form
        setFormName('');
        setSelectedFields([]);
        setSelectedCategory('');
        setAvailableFields([]);
        setFieldGroups({});
        setEditingForm(null);
    };

    const handleCloseView = () => {
        setViewingForm(null);
    };

    return (
        <div className="form-dashboard-modal-overlay">
            <div className="form-dashboard-modal-content">
                <button onClick={onClose} className="form-dashboard-close-button">×</button>
                <h3 className="form-dashboard-title">Form Creation for {organization}</h3>
                <div className="form-dashboard-section">
                    <h4 className="form-dashboard-subtitle">{editingForm ? 'Edit Form' : 'Create New Form'}</h4>
                    <div className="form-dashboard-form">
                        <label className="form-dashboard-label">
                            Form Name:
                            <input
                                type="text"
                                value={formName}
                                onChange={(e) => setFormName(e.target.value)}
                                placeholder="Enter form name"
                                className="form-dashboard-input"
                            />
                        </label>
                        <label className="form-dashboard-label">
                            Select Category:
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form-dashboard-select"
                            >
                                <option value="">--Select--</option>
                                {categories.map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        {Object.keys(fieldGroups).length > 0 && (
                            <div className="form-dashboard-fields-container">
                                {Object.keys(fieldGroups).map((groupName) => {
                                    const groupFields = fieldGroups[groupName];
                                    const allSelected = groupFields.every(field => selectedFields.includes(field.id));
                                    return (
                                        <div key={groupName} className="form-dashboard-field-group">
                                            <h5 className="form-dashboard-field-group-title">
                                                {groupName}
                                                <label className="form-dashboard-select-all-label">
                                                    <input
                                                        type="checkbox"
                                                        checked={allSelected}
                                                        onChange={() => handleSelectAll(groupName)}
                                                        className="form-dashboard-checkbox"
                                                    />
                                                    Select All
                                                </label>
                                            </h5>
                                            {groupFields.map((field) => (
                                                <div key={field.id} className="form-dashboard-field-item">
                                                    <label className="form-dashboard-field-label">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedFields.includes(field.id)}
                                                            onChange={() => handleFieldSelection(field.id)}
                                                            className="form-dashboard-checkbox"
                                                        />
                                                        {field.field_name} ({field.field_type})
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div className="form-dashboard-button-group">
                            <button onClick={handleSaveForm} className="form-dashboard-save-button">
                                {editingForm ? 'Update Form' : 'Save Form'}
                            </button>
                            {editingForm && (
                                <button onClick={handleCancelEdit} className="form-dashboard-cancel-button">
                                    Cancel Edit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="form-dashboard-section">
                    <h4 className="form-dashboard-subtitle">Existing Forms</h4>
                    {existingForms.length > 0 ? (
                        <ul className="form-dashboard-form-list">
                            {existingForms.map((form) => (
                                <li key={form.id} className="form-dashboard-form-item">
                                    <span className="form-dashboard-form-name">{form.formName}</span>
                                    <div className="form-dashboard-form-actions">
                                        <button onClick={() => handleViewForm(form)} className="form-dashboard-view-button">
                                            View
                                        </button>
                                        <button onClick={() => handleEditForm(form)} className="form-dashboard-edit-button">
                                            Edit
                                        </button>
                                        <button onClick={() => handleDeleteForm(form.id)} className="form-dashboard-delete-button">
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No forms available.</p>
                    )}
                </div>
                {viewingForm && (
                    <div className="form-dashboard-view-modal">
                        <div className="form-dashboard-view-content">
                            <h4>{viewingForm.formName}</h4>
                            <p><strong>Category:</strong> {viewingForm.categoryName}</p>
                            <p><strong>Fields:</strong></p>
                            <ul>
                                {viewingForm.fields.map((field) => (
                                    <li key={field.id}>
                                        {field.field_name} ({field.field_type}) - {field.sourceTable}
                                    </li>
                                ))}
                            </ul>
                            <button onClick={handleCloseView} className="form-dashboard-close-view-button">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FormCreationDashboard;
