import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

console.log('Before service worker registration code.');

if ('serviceWorker' in navigator) {
    console.log('Service Worker is supported.');

    window.addEventListener('load', () => {
        console.log('Window load event fired.');

        navigator.serviceWorker
            .register(`${process.env.PUBLIC_URL}/service-worker.js`)
            .then((registration) => {
                console.log('Service Worker registered:', registration);
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    });
} else {
    console.log('Service Worker is not supported in this browser.');
}

console.log('After service worker registration code.');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
