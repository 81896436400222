import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { FaBars, FaHome, FaUserShield } from 'react-icons/fa';
import { FiLogOut, FiLogIn } from 'react-icons/fi';

const nodeIcons = {
    AridLands: '/images/arid.png',
    EFTEON: '/images/EFTEON.png',
    Elwandle: '/images/elwandle.png',
    Egagasini: '/images/egagasini.png',
    Fynbos: '/images/fynbos.png',
    GrassLands: '/images/grasslands.png',
    Ndlovu: '/images/ndlovu.png',
    SAPRI: '/images/sapri.png',
    SMCRI: '/images/smcri.png',
    SAEON: '/images/SAEON_logo.png',
};

function Navbar({ openLoginModal, user, onLogout, selectedNode, setSelectedNode }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const nodes = ['AridLands', 'EFTEON', 'Elwandle', 'Egagasini', 'Fynbos', 'GrassLands', 'Ndlovu', 'SAPRI', 'SMCRI', 'SAEON'];
    const baseOptions = ['Home', 'Operations Dashboard', 'Online Forms', 'Offline Forms'];

    useEffect(() => {
        if (!user) {
            setSelectedNode('');
        } else if (user.role === 'Technician' && user.organizations.length === 1) {
            setSelectedNode(user.organizations[0]);
        }
    }, [user, setSelectedNode]);

    const handleNodeSelect = (event) => {
        const value = event.target.value;
        setSelectedNode(value);
        setIsMenuOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <nav className="top-navbar">
                <div className="navbar-left">
                    {user && (
                        <button onClick={toggleMenu} className="menu-button">
                            <FaBars />
                        </button>
                    )}
                    <h1 className="navbar-title">SAEON OIMS</h1>
                </div>
                <div className="navbar-right">
                    {user ? (
                        <button
                            onClick={() => {
                                onLogout();
                                setSelectedNode('');
                            }}
                            className="navbar-button"
                        >
                            <FiLogOut />
                        </button>
                    ) : (
                        <button onClick={openLoginModal} className="navbar-button">
                            <FiLogIn />
                        </button>
                    )}
                </div>
            </nav>

            {user && (
                <>
                    <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
                        <div className="menu-content">
                            <div className="user-info">
                                <p className="navbar-username">Logged in as {user.username}</p>
                            </div>

                            <div className="navbar-dropdown">
                                <label htmlFor="node-select" className="dropdown-label">
                                    Select Node:
                                </label>
                                <select
                                    id="node-select"
                                    className="navbar-select"
                                    value={selectedNode}
                                    onChange={handleNodeSelect}
                                >
                                    <option value="">Select a Node...</option>
                                    {user.role === 'Admin'
                                        ? nodes.map((node) => (
                                            <option key={node} value={node}>
                                                {node}
                                            </option>
                                        ))
                                        : user.organizations.map((org) => (
                                            <option key={org} value={org}>
                                                {org}
                                            </option>
                                        ))}
                                </select>
                            </div>

                            {selectedNode && (
                                <div className="navbar-options">
                                    {baseOptions.map((option) => (
                                        <NavLink
                                            key={option}
                                            to={`/${selectedNode.toLowerCase()}/${option.toLowerCase().replace(' ', '-')}`}
                                            className="navbar-link"
                                            activeClassName="active"
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            {option === 'Home' ? (
                                                <FaHome className="navbar-link-icon" />
                                            ) : (
                                                <img
                                                    src={nodeIcons[selectedNode] || nodeIcons.SMCRI}
                                                    alt={`${selectedNode} icon`}
                                                    className="navbar-link-icon"
                                                />
                                            )}
                                            {option}
                                        </NavLink>
                                    ))}

                                    {user && user.role === 'Admin' && (
                                        <NavLink
                                            to="/admin-panel"
                                            className="navbar-link"
                                            activeClassName="active"
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            <FaUserShield className="navbar-link-icon" /> {/* Admin icon */}
                                            Admin Panel
                                        </NavLink>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
                </>
            )}
        </>
    );
}

export default Navbar;
