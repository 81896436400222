import React, { useState, useEffect } from 'react';
import './CategoryManagementModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faTrash, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

function CategoryManagementModal({ onClose, organization, onCategoriesUpdated }) {
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [acronym, setAcronym] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    // Fetch categories when the component loads
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`/api/assets/categories?organization=${organization}`);
            if (!response.ok) throw new Error('Failed to fetch categories');
            const data = await response.json();
            setCategories(data);

            // Notify the parent component of the updated categories
            if (onCategoriesUpdated) {
                onCategoriesUpdated(data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            showMessage('Error fetching categories', 'error');
        }
    };

    const showMessage = (msg, type) => {
        setMessage(msg);
        setMessageType(type);
        setTimeout(() => setMessage(null), 3000);
    };

    const handleSelectCategory = (categoryId) => {
        const selectedCategory = categories.find(category => category.id === categoryId);
        if (selectedCategory) {
            setEditingCategoryId(categoryId);
            setSelectedCategoryId(categoryId);
            setCategoryName(selectedCategory.name);
            setAcronym(selectedCategory.acronym);
            setCategoryDescription(selectedCategory.description);
        } else {
            handleCancelEdit();
        }
    };

    const handleSaveCategory = async () => {
        const url = editingCategoryId
            ? `/api/assets/categories/${editingCategoryId}`
            : '/api/assets/categories';
        const method = editingCategoryId ? 'PUT' : 'POST';
        const body = {
            organization,
            name: categoryName,
            acronym,
            description: categoryDescription,
        };

        try {
            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.message.includes("Category name already exists")) {
                    showMessage('Category name already exists', 'error');
                } else {
                    showMessage('Failed to save category', 'error');
                }
                return;
            }

            await fetchCategories();
            handleCancelEdit();
            showMessage(editingCategoryId ? 'Category updated successfully' : 'Category added successfully', 'success');
        } catch (error) {
            console.error('Error saving category:', error);
            showMessage('Error saving category', 'error');
        }
    };

    const handleDeleteCategory = async () => {
        if (!selectedCategoryId) return;

        try {
            const response = await fetch(`/api/assets/categories/${selectedCategoryId}`, { method: 'DELETE' });
            if (!response.ok) throw new Error('Failed to delete category');
            await fetchCategories();
            handleCancelEdit();
            showMessage('Category deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting category:', error);
            showMessage('Error deleting category', 'error');
        }
    };

    const handleCancelEdit = () => {
        setEditingCategoryId(null);
        setSelectedCategoryId(null);
        setCategoryName('');
        setAcronym('');
        setCategoryDescription('');
    };

    const confirmAndExecute = (action) => {
        setConfirmAction(() => action);
        setShowConfirmModal(true);
    };

    const executeConfirmedAction = () => {
        if (confirmAction) confirmAction();
        setShowConfirmModal(false);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Manage Asset Categories for {organization}</h2>

                {message && (
                    <div className={`message-popup ${messageType}`}>
                        {message}
                    </div>
                )}

                <div className="category-dropdown">
                    <label htmlFor="categorySelect">Select Category:</label>
                    <select
                        id="categorySelect"
                        value={selectedCategoryId || ''}
                        onChange={(e) => handleSelectCategory(e.target.value)}
                    >
                        <option value="">Add New Category</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <h3>{editingCategoryId ? 'Edit Category' : 'Add New Category'}</h3>
                <label>Category Name</label>
                <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    placeholder="Enter category name"
                />

                <label>Acronym</label>
                <input
                    type="text"
                    value={acronym}
                    onChange={(e) => setAcronym(e.target.value)}
                    placeholder="Enter acronym"
                />

                <label>Description</label>
                <textarea
                    value={categoryDescription}
                    onChange={(e) => setCategoryDescription(e.target.value)}
                    placeholder="Enter category description"
                />

                <div className="button-group">
                    <button onClick={() => confirmAndExecute(handleSaveCategory)} className="button-save">
                        <FontAwesomeIcon icon={editingCategoryId ? faEdit : faSave} />
                        {editingCategoryId ? 'Update' : 'Save'}
                    </button>
                    {editingCategoryId && (
                        <button onClick={() => confirmAndExecute(handleDeleteCategory)} className="button-delete">
                            <FontAwesomeIcon icon={faTrash} /> Delete
                        </button>
                    )}
                    <button onClick={onClose} className="button-cancel">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </button>
                </div>
            </div>

            {showConfirmModal && (
                <div className="confirm-modal">
                    <div className="confirm-modal-content">
                        <p>Are you sure you want to proceed?</p>
                        <div className="confirm-buttons">
                            <button onClick={executeConfirmedAction} className="button-confirm">
                                <FontAwesomeIcon icon={faCheck} /> Yes
                            </button>
                            <button onClick={() => setShowConfirmModal(false)} className="button-cancel">
                                <FontAwesomeIcon icon={faTimes} /> No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CategoryManagementModal;
