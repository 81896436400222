import React, { useState, useEffect } from 'react';
import './DataManagementModal.css';

function DataManagementModal({ onClose, organization }) {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentEditValues, setCurrentEditValues] = useState(null);

    // Fetch categories from the API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                setError('');
                const response = await fetch(`/api/assets/categories?organization=${organization}`);
                if (!response.ok) throw new Error('Failed to fetch categories');
                const data = await response.json();
                setCategories(data.sort((a, b) => a.name.localeCompare(b.name))); // Sort alphabetically
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to fetch categories. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchCategories();
    }, [organization]);

    // Fetch fields and values from the API
    useEffect(() => {
        if (!selectedCategory) {
            setFields([]);
            setFieldValues([]);
            return;
        }

        const fetchFieldsAndValues = async () => {
            setIsLoading(true);
            try {
                const [fieldsResponse, valuesResponse] = await Promise.all([
                    fetch(`/api/assets/fields?category_id=${selectedCategory}`).then((res) => res.json()),
                    fetch(`/api/assets/values?category_id=${selectedCategory}`).then((res) => res.json()),
                ]);
                setFields(fieldsResponse);
                setFieldValues(valuesResponse);
            } catch (err) {
                console.error('Error fetching fields or values:', err);
                setError('Failed to fetch fields or values. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchFieldsAndValues();
    }, [selectedCategory]);

    const groupedValues = fieldValues.reduce((acc, value) => {
        acc[value.asset_id] = acc[value.asset_id] || {};
        acc[value.asset_id][value.field_id] = value.field_value;
        return acc;
    }, {});

    const handleAdd = () => setShowAddModal(true);
    const handleEdit = (values) => {
        setCurrentEditValues(values);
        setShowEditModal(true);
    };
    const handleDelete = async (assetId) => {
        if (!window.confirm('Are you sure you want to delete this entry?')) return;

        try {
            const response = await fetch(`/api/assets/delete-field-value/${assetId}`, { method: 'DELETE' });
            if (!response.ok) throw new Error('Failed to delete entry');
            setFieldValues((prevValues) => prevValues.filter((value) => value.asset_id !== assetId));
        } catch (err) {
            console.error('Error deleting entry:', err);
            setError('Failed to delete entry. Please try again later.');
        }
    };

    const handleAddSubmit = async (newValues) => {
        try {
            const response = await fetch(`/api/assets/add-field-value`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newValues),
            });
            if (!response.ok) throw new Error('Failed to add entry');
            const addedValue = await response.json();
            setFieldValues((prevValues) => [...prevValues, addedValue]);
            setShowAddModal(false);
        } catch (err) {
            console.error('Error adding entry:', err);
            setError('Failed to add entry. Please try again later.');
        }
    };

    const handleEditSubmit = async (updatedValues) => {
        try {
            const response = await fetch(`/api/assets/update-field-value/${updatedValues.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedValues),
            });
            if (!response.ok) throw new Error('Failed to update entry');
            const updatedValue = await response.json();
            setFieldValues((prevValues) =>
                prevValues.map((value) => (value.id === updatedValue.id ? updatedValue : value))
            );
            setShowEditModal(false);
        } catch (err) {
            console.error('Error updating entry:', err);
            setError('Failed to update entry. Please try again later.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button onClick={onClose} className="close-button">Close</button>
                <h2>Data Management</h2>

                {/* Category Dropdown */}
                <div className="category-select-container">
                    <label htmlFor="category-select">Select Category:</label>
                    <select
                        id="category-select"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="category-dropdown"
                    >
                        <option value="">-- Select a Category --</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Error Message */}
                {error && <p className="error-message">{error}</p>}

                {/* Loading Indicator */}
                {isLoading && <p>Loading...</p>}

                {/* Dynamic Table */}
                {selectedCategory && !isLoading && !error && fields.length > 0 && (
                    <div className="data-table-container">
                        <button className="add-button" onClick={handleAdd}>
                            Add New Entry
                        </button>
                        <table className="data-table">
                            <thead>
                            <tr>
                                {fields.map((field) => (
                                    <th key={field.id}>{field.field_name}</th>
                                ))}
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.entries(groupedValues).map(([assetId, values]) => (
                                <tr key={assetId}>
                                    {fields.map((field) => (
                                        <td key={field.id}>{values[field.id] || 'N/A'}</td>
                                    ))}
                                    <td>
                                        <button
                                            className="edit-button"
                                            onClick={() => handleEdit(values)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="delete-button"
                                            onClick={() => handleDelete(assetId)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* No Fields Message */}
                {selectedCategory && !isLoading && fields.length === 0 && (
                    <p>No fields available for this category.</p>
                )}

                {/* Add Modal */}
                {showAddModal && (
                    <div className="modal">
                        <h3>Add New Entry</h3>
                        {/* Add form logic here */}
                        <button onClick={() => setShowAddModal(false)}>Close</button>
                        <button onClick={handleAddSubmit}>Submit</button>
                    </div>
                )}

                {/* Edit Modal */}
                {showEditModal && (
                    <div className="modal">
                        <h3>Edit Entry</h3>
                        {/* Edit form logic here */}
                        <button onClick={() => setShowEditModal(false)}>Close</button>
                        <button onClick={handleEditSubmit}>Submit</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DataManagementModal;
