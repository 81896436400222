// src/pages/elwandle/ElwandleDashboard.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ElwandleDashboard.css';
import { FaHome, FaInfoCircle } from 'react-icons/fa';
import AssetManagementModal from './AssetManagementModal';
import CategoryManagementModal from './CategoryManagementModal';
import InfoModal from './InfoModal';
import FormCreationDashboard from './FormCreationDashboard';
import OfflineEntryDashboard from './OfflineEntryDashboard';
import DataManagementModal from './DataManagementModal'; // Import the new Data Management component
import { openIndexedDB } from '../../utils/indexedDB';

async function checkPendingUploads(organization) {
    const db = await openIndexedDB();

    // Check 'formInstances' object store
    const formInstancesTx = db.transaction('formInstances', 'readonly');
    const formInstancesStore = formInstancesTx.objectStore('formInstances');

    // Retrieve all form instances
    const request = formInstancesStore.getAll();

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            const allInstances = request.result;
            // Filter instances by organization
            const instancesForOrg = allInstances.filter(instance => instance.organization === organization);
            resolve(instancesForOrg.length);
        };
        request.onerror = () => reject(request.error);
    });
}

function ElwandleDashboard() {
    const { state } = useLocation();
    const { user, selectedNode } = state || {};
    const navigate = useNavigate();

    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isFormCreationOpen, setIsFormCreationOpen] = useState(false);
    const [isOfflineEntryOpen, setIsOfflineEntryOpen] = useState(false);
    const [infoContent, setInfoContent] = useState('');
    const [isDataManagementOpen, setIsDataManagementOpen] = useState(false); // State for Data Management Modal
    const [pendingUploads, setPendingUploads] = useState(0);

    const updatePendingUploads = async () => {
        const count = await checkPendingUploads(selectedNode);
        setPendingUploads(count);
    };

    useEffect(() => {
        updatePendingUploads();
    }, [selectedNode]);

    const handleBackToHome = () => {
        navigate('/');
    };

    const handleOpenAssetModal = () => {
        setIsAssetModalOpen(true);
    };

    const handleCloseAssetModal = async () => {
        setIsAssetModalOpen(false);
        await updatePendingUploads();
    };

    const handleOpenCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const handleCloseCategoryModal = () => {
        setIsCategoryModalOpen(false);
    };

    const handleOpenInfoModal = (content) => {
        setInfoContent(content);
        setIsInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
        setIsInfoModalOpen(false);
        setInfoContent('');
    };

    const handleOpenFormCreation = () => {
        setIsFormCreationOpen(true);
    };

    const handleCloseFormCreation = () => {
        setIsFormCreationOpen(false);
    };

    const handleOpenOfflineEntry = () => {
        setIsOfflineEntryOpen(true);
    };

    const handleCloseOfflineEntry = async () => {
        setIsOfflineEntryOpen(false);
        await updatePendingUploads();
    };

    const handleOpenDataManagement = () => setIsDataManagementOpen(true); // Open Data Management Modal

    const handleCloseDataManagement = () => setIsDataManagementOpen(false); // Close Data Management Modal


    return (
        <div className="operations-dashboard">
            {/* Home Button as Icon */}
            <div className="home-button" onClick={handleBackToHome}>
                <FaHome />
            </div>

            {/* Pending Uploads Badge */}
            {pendingUploads > 0 && (
                <span className="badge">{pendingUploads} Pending</span>
            )}

            <h1>{selectedNode} Asset Categories</h1>
            {user && <p>Welcome, {user.username}. You are viewing the asset categories for {selectedNode}.</p>}

            <div className="dashboard-content">





                <div className="dashboard-section" onClick={handleOpenCategoryModal}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Category Management');
                        }}
                    />
                    <h3>Category Management</h3>
                    <p>Click here to create and manage categories and fields.</p>
                </div>

                <div className="dashboard-section manage-assets-section" onClick={handleOpenAssetModal}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Asset Field Configuration');
                        }}
                    />
                    <h3>Field Configuration</h3>
                    <p>Click here to configure fields for each category under {selectedNode}.</p>
                </div>



                {/* Form Creation Section */}
                <div className="dashboard-section form-creation-section" onClick={handleOpenFormCreation}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Form Creation.');
                        }}
                    />
                    <h3>Form Creation</h3>
                    <p>Create forms for {selectedNode} categories and save them offline.</p>
                </div>

                {/* Offline Data Entry Section */}
                <div className="dashboard-section offline-entry-section" onClick={handleOpenOfflineEntry}>
                    <FaInfoCircle
                        className="info-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInfoModal('Information about Offline Data Entry.');
                        }}
                    />
                    <h3>Offline Data Entry</h3>
                    <p>Enter data offline using saved forms.</p>
                </div>

                {/* New Data Management Section */}
                <div className="dashboard-section" onClick={handleOpenDataManagement}>
                    <FaInfoCircle className="info-icon" onClick={(e) => {
                        e.stopPropagation();
                        handleOpenInfoModal('Information about Data Management');
                    }} />
                    <h3>Data Management</h3>
                    <p>View, edit, and delete existing data entries for {selectedNode}.</p>
                </div>


                {isDataManagementOpen && (
                    <DataManagementModal
                        onClose={handleCloseDataManagement}
                        user={user}
                        organization={selectedNode}
                    />
                )}
                {/* FormCreationDashboard Modal */}
                {isFormCreationOpen && (
                    <FormCreationDashboard
                        onClose={handleCloseFormCreation}
                        user={user}
                        organization={selectedNode}
                    />
                )}



                {isOfflineEntryOpen && (
                    <OfflineEntryDashboard
                        onClose={handleCloseOfflineEntry}
                        user={user}
                        organization={selectedNode}
                        updatePendingUploads={updatePendingUploads}
                    />
                )}
            </div>

            {isAssetModalOpen && (
                <AssetManagementModal
                    onClose={handleCloseAssetModal}
                    organization={selectedNode}
                />
            )}

            {isCategoryModalOpen && (
                <CategoryManagementModal
                    onClose={handleCloseCategoryModal}
                    organization={selectedNode}
                />
            )}

            {isInfoModalOpen && (
                <InfoModal onClose={handleCloseInfoModal} content={infoContent} />
            )}
        </div>
    );
}

export default ElwandleDashboard;
