// src/pages/elwandle/OfflineEntryDashboard.js

import React, { useState, useEffect } from 'react';
import './OfflineEntryDashboard.css';
import { openIndexedDB } from '../../utils/indexedDB';

// Function to get form templates from IndexedDB
async function getFormTemplatesFromIndexedDB() {
    const db = await openIndexedDB();
    const tx = db.transaction('forms', 'readonly');
    const store = tx.objectStore('forms');

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => {
            resolve(request.result);
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

// Function to get form instances from IndexedDB
async function getFormInstancesFromIndexedDB() {
    const db = await openIndexedDB();
    const tx = db.transaction('formInstances', 'readonly');
    const store = tx.objectStore('formInstances');

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => {
            resolve(request.result);
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

// Function to save a form instance to IndexedDB
async function saveFormInstanceToIndexedDB(formInstance) {
    const db = await openIndexedDB();
    const tx = db.transaction('formInstances', 'readwrite');
    const store = tx.objectStore('formInstances');
    const request = store.put(formInstance);

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            resolve();
        };
        request.onerror = (event) => {
            reject(event.target.error);
        };
    });
}

// Function to delete a form instance from IndexedDB
async function deleteFormInstanceFromIndexedDB(instanceId) {
    const db = await openIndexedDB();
    const tx = db.transaction('formInstances', 'readwrite');
    const store = tx.objectStore('formInstances');
    const request = store.delete(instanceId);

    return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = (event) => reject(event.target.error);
    });
}

// Function to get dropdown options from IndexedDB
async function getDropdownOptionsFromIndexedDB(fieldId) {
    const db = await openIndexedDB();
    const tx = db.transaction('dropdownOptions', 'readonly');
    const store = tx.objectStore('dropdownOptions');

    return new Promise((resolve, reject) => {
        const request = store.get(fieldId);
        request.onsuccess = () => {
            resolve(request.result ? request.result.options : []);
        };
        request.onerror = () => {
            reject(request.error);
        };
    });
}

// The main component
function OfflineEntryDashboard({ onClose, user, organization, updatePendingUploads }) {
    const [formTemplates, setFormTemplates] = useState([]);
    const [formInstances, setFormInstances] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [entryData, setEntryData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [imagePreviews, setImagePreviews] = useState({});
    const [manualCoordinatesFields, setManualCoordinatesFields] = useState({});
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch form templates and instances on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const templates = await getFormTemplatesFromIndexedDB();
                const instances = await getFormInstancesFromIndexedDB();

                const orgTemplates = templates.filter(form => form.organization === organization);
                const orgInstances = instances.filter(instance => instance.organization === organization);

                setFormTemplates(orgTemplates);
                setFormInstances(orgInstances);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [organization]);

    // Fetch dropdown options when a form instance is selected
    useEffect(() => {
        const fetchDropdownOptions = async () => {
            if (!selectedInstance) return;

            const optionsMap = {};
            for (const field of selectedInstance.fields) {
                if (field.field_type === 'dropdown') {
                    try {
                        const options = await getDropdownOptionsFromIndexedDB(field.id);
                        optionsMap[field.id] = options; // Map field ID to its options
                    } catch (error) {
                        console.error(`Error fetching options for field ${field.id}:`, error);
                        optionsMap[field.id] = []; // Fallback to empty array if an error occurs
                    }
                }
            }
            setDropdownOptions(optionsMap); // Update state with the fetched options
        };

        fetchDropdownOptions();
    }, [selectedInstance]);

    // Handle creating a new form instance
    const handleCreateNewInstance = async (templateId) => {
        const template = formTemplates.find(form => form.id === parseInt(templateId));
        if (template) {
            const instanceName = prompt('Enter a name for this form instance (e.g., V1 - or (version 1):');
            if (!instanceName) {
                alert('Instance name is required.');
                return;
            }
            const newInstance = {
                instanceId: Date.now(),
                formId: template.id,
                formName: template.formName,
                instanceName,
                organization: template.organization,
                categoryId: template.categoryId, // Include categoryId
                fields: template.fields,
                data: {},
                status: 'In Progress',
                createdBy: user.username,
                createdAt: new Date(),
                updatedAt: new Date(),
                synced: false,
            };
            try {
                await saveFormInstanceToIndexedDB(newInstance);
                setFormInstances([...formInstances, newInstance]);
                setSelectedInstance(newInstance);
                setEntryData(newInstance.data);
                setValidationErrors({});
                setImagePreviews({});
                setManualCoordinatesFields({});

                if (updatePendingUploads) {
                    updatePendingUploads();
                }
            } catch (error) {
                console.error('Error creating new form instance:', error);
            }
        }
    };

    // Handle selecting an existing form instance
    const handleSelectInstance = (instanceId) => {
        const instance = formInstances.find(inst => inst.instanceId === parseInt(instanceId));
        if (instance) {
            setSelectedInstance(instance);
            setEntryData(instance.data || {});
            setValidationErrors({});

            // Set image previews for any existing image data
            const previews = {};
            for (const fieldId in instance.data) {
                if (instance.data.hasOwnProperty(fieldId)) {
                    const field = instance.fields.find(f => f.id === fieldId);
                    if (field && field.field_type === 'image') {
                        previews[fieldId] = instance.data[fieldId];
                    }
                }
            }
            setImagePreviews(previews);
            setManualCoordinatesFields({});
        }
    };

    // Handle deleting a form instance
    const handleDeleteInstance = async (instanceId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this form instance?');
        if (confirmDelete) {
            try {
                await deleteFormInstanceFromIndexedDB(instanceId);
                setFormInstances(formInstances.filter(inst => inst.instanceId !== instanceId));
                if (selectedInstance && selectedInstance.instanceId === instanceId) {
                    setSelectedInstance(null);
                    setEntryData({});
                    setImagePreviews({});
                    setManualCoordinatesFields({});
                }

                if (updatePendingUploads) {
                    updatePendingUploads();
                }
            } catch (error) {
                console.error('Error deleting form instance:', error);
            }
        }
    };

    // Validate required fields
    const validateFields = () => {
        const errors = {};
        selectedInstance.fields.forEach(field => {
            const fieldIdStr = String(field.id);
            if (field.is_required) {
                const value = entryData[fieldIdStr];
                if (value === undefined || value === '') {
                    errors[fieldIdStr] = 'This field is required.';
                } else if (field.field_type === 'coordinates') {
                    // Validate coordinate format
                    const coordsRegex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;
                    if (!coordsRegex.test(value)) {
                        errors[fieldIdStr] = 'Please enter valid coordinates (latitude, longitude).';
                    }
                }
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Handle field changes
    const handleFieldChange = (fieldId, value) => {
        const fieldIdStr = String(fieldId);
        const updatedData = { ...entryData, [fieldIdStr]: value };
        setEntryData(updatedData);

        if (validationErrors[fieldIdStr] && value) {
            const updatedErrors = { ...validationErrors };
            delete updatedErrors[fieldIdStr];
            setValidationErrors(updatedErrors);
        }

        if (selectedInstance) {
            selectedInstance.data = updatedData;
            selectedInstance.updatedAt = new Date();
            saveFormInstanceToIndexedDB(selectedInstance);
        }
    };

    // Handle deleting a field's value
    const handleDeleteFieldValue = (fieldId) => {
        const fieldIdStr = String(fieldId);
        const updatedData = { ...entryData };
        delete updatedData[fieldIdStr];
        setEntryData(updatedData);

        // Update image previews if it's an image field
        const field = selectedInstance.fields.find(f => f.id === fieldIdStr);
        if (field.field_type === 'image') {
            const updatedPreviews = { ...imagePreviews };
            delete updatedPreviews[fieldIdStr];
            setImagePreviews(updatedPreviews);
        }

        if (selectedInstance) {
            selectedInstance.data = updatedData;
            selectedInstance.updatedAt = new Date();
            saveFormInstanceToIndexedDB(selectedInstance);
        }
    };

    // Handle getting coordinates
    const handleGetCoordinates = (fieldId) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coords = `${position.coords.latitude}, ${position.coords.longitude}`;
                    handleFieldChange(fieldId, coords);
                },
                (error) => {
                    alert('Error getting location: ' + error.message);
                },
                { enableHighAccuracy: true }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    // Handle capturing image
    const handleCaptureImage = (fieldId) => {
        const inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.accept = 'image/*';
        inputElement.capture = 'environment';
        inputElement.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                // Read the file as a data URL and store it
                const reader = new FileReader();
                reader.onload = (e) => {
                    const dataUrl = e.target.result;
                    handleFieldChange(fieldId, dataUrl);
                    setImagePreviews(prev => ({ ...prev, [fieldId]: dataUrl }));
                };
                reader.readAsDataURL(file);
            }
        };
        inputElement.click();
    };

    // Toggle between manual and automatic coordinate entry
    const toggleManualCoordinates = (fieldId) => {
        const fieldIdStr = String(fieldId);
        setManualCoordinatesFields(prev => ({
            ...prev,
            [fieldIdStr]: !prev[fieldIdStr],
        }));
    };

    // Handle field rendering based on type
    const renderField = (field) => {
        const fieldIdStr = String(field.id);
        const isRequired = field.is_required;
        const hasError = validationErrors[fieldIdStr];
        const fieldValue = entryData[fieldIdStr] || '';
        const commonProps = {
            id: fieldIdStr,
            name: field.field_name,
            value: fieldValue,
            onChange: (e) => handleFieldChange(fieldIdStr, e.target.value),
            className: `offline-entry-input ${
                hasError
                    ? 'offline-entry-error-input'
                    : isRequired && !fieldValue
                    ? 'offline-entry-warning-input'
                    : ''
            }`,
        };

        switch (field.field_type) {
            case 'boolean':
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="checkbox"
                            checked={!!fieldValue}
                            onChange={(e) => handleFieldChange(fieldIdStr, e.target.checked)}
                            className={`offline-entry-checkbox ${
                                hasError
                                    ? 'offline-entry-error-input'
                                    : isRequired && !fieldValue
                                    ? 'offline-entry-warning-input'
                                    : ''
                            }`}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            case 'coordinates':
                return (
                    <div
                        key={fieldIdStr}
                        className={`offline-entry-field-item ${
                            hasError ? 'offline-entry-error' : isRequired && !fieldValue ? 'offline-entry-warning' : ''
                        }`}
                    >
                        <label className="offline-entry-label">
                            {field.field_name} {isRequired && '*'}:
                        </label>
                        {manualCoordinatesFields[fieldIdStr] ? (
                            <input
                                type="text"
                                placeholder="Enter coordinates (latitude, longitude)"
                                {...commonProps}
                            />
                        ) : (
                            <input
                                type="text"
                                readOnly
                                value={fieldValue}
                                className={commonProps.className}
                            />
                        )}
                        <div className="offline-entry-button-group">
                            {!manualCoordinatesFields[fieldIdStr] && (
                                <button
                                    type="button"
                                    onClick={() => handleGetCoordinates(fieldIdStr)}
                                    className="offline-entry-button"
                                >
                                    {fieldValue ? 'Retake Location' : 'Get Location'}
                                </button>
                            )}
                            <button
                                type="button"
                                onClick={() => toggleManualCoordinates(fieldIdStr)}
                                className="offline-entry-button"
                            >
                                {manualCoordinatesFields[fieldIdStr] ? 'Use GPS' : 'Enter Manually'}
                            </button>
                            {fieldValue && (
                                <button
                                    type="button"
                                    onClick={() => handleDeleteFieldValue(fieldIdStr)}
                                    className="offline-entry-button offline-entry-delete-button"
                                >
                                    Delete Coordinates
                                </button>
                            )}
                        </div>
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </div>
                );
            case 'date':
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="date"
                            {...commonProps}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            case 'time':
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="time"
                            {...commonProps}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            case 'integer':
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="number"
                            step="1"
                            {...commonProps}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            case 'numeric':
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="number"
                            {...commonProps}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            case 'image':
                return (
                    <div
                        key={fieldIdStr}
                        className={`offline-entry-field-item ${
                            hasError ? 'offline-entry-error' : isRequired && !fieldValue ? 'offline-entry-warning' : ''
                        }`}
                    >
                        <label className="offline-entry-label">
                            {field.field_name} {isRequired && '*'}:
                        </label>
                        {fieldValue ? (
                            <div className="offline-entry-image-container">
                                <img
                                    src={imagePreviews[fieldIdStr]}
                                    alt="Captured"
                                    className="offline-entry-image-preview"
                                />
                                <div className="offline-entry-button-group">
                                    <button
                                        type="button"
                                        onClick={() => handleCaptureImage(fieldIdStr)}
                                        className="offline-entry-button"
                                    >
                                        Retake Image
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteFieldValue(fieldIdStr)}
                                        className="offline-entry-button offline-entry-delete-button"
                                    >
                                        Delete Image
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <button
                                type="button"
                                onClick={() => handleCaptureImage(fieldIdStr)}
                                className="offline-entry-button"
                            >
                                Capture Image
                            </button>
                        )}
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </div>
                );
            case 'dropdown': {
                const options = dropdownOptions[field.id] || []; // Use pre-fetched options for this field
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <select
                            {...commonProps}
                            value={fieldValue} // Ensure value is properly controlled
                            onChange={(e) => handleFieldChange(fieldIdStr, e.target.value)}
                        >
                            <option value="">-- Select --</option>
                            {options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
            }
            default:
                return (
                    <label className="offline-entry-label" key={fieldIdStr}>
                        {field.field_name} {isRequired && '*'}:
                        <input
                            type="text"
                            {...commonProps}
                        />
                        {hasError && <span className="offline-entry-error-text">{hasError}</span>}
                    </label>
                );
        }
    };

    // Handle saving the entry without validation
    const handleSaveEntry = async () => {
        if (selectedInstance) {
            selectedInstance.data = entryData;
            selectedInstance.status = 'In Progress';
            selectedInstance.updatedAt = new Date();
            selectedInstance.synced = false;
            try {
                await saveFormInstanceToIndexedDB(selectedInstance);
                alert('Progress saved offline.');
                if (updatePendingUploads) {
                    updatePendingUploads();
                }
            } catch (error) {
                console.error('Error saving entry:', error);
                alert('Failed to save entry.');
            }
        } else {
            alert('Please select a form instance.');
        }
    };

    // Handle submitting the entry with validation
    const handleSubmitEntry = async () => {
        if (selectedInstance) {
            const isValid = validateFields();
            if (isValid) {
                setIsSubmitting(true);
                try {
                    // Prepare the data for submission
                    const formData = new FormData();

                    // Add basic form instance data
                    formData.append('formInstance', JSON.stringify({
                        instanceId: selectedInstance.instanceId,
                        formId: selectedInstance.formId,
                        formName: selectedInstance.formName,
                        instanceName: selectedInstance.instanceName,
                        organization: selectedInstance.organization,
                        categoryId: selectedInstance.categoryId, // Include categoryId
                        fields: selectedInstance.fields,
                        data: {}, // We'll add data separately
                        status: 'Completed',
                        createdBy: selectedInstance.createdBy,
                        createdAt: selectedInstance.createdAt,
                        updatedAt: new Date(),
                    }));

                    // Process each field
                    for (const field of selectedInstance.fields) {
                        const fieldIdStr = String(field.id);
                        const fieldValue = entryData[fieldIdStr];

                        if (field.field_type === 'image' && fieldValue) {
                            // Handle images as before
                            const blob = await fetch(fieldValue).then(res => res.blob());
                            const imageName = `${field.field_name}_${Date.now()}.jpg`;
                            formData.append('images', blob, imageName);
                            // Add a reference to the image field
                            formData.append(`data_${fieldIdStr}`, `__IMAGE__${imageName}`);
                        } else {
                            // Append all other field values, including empty strings
                            formData.append(`data_${fieldIdStr}`, fieldValue !== undefined ? fieldValue : '');
                        }
                    }

                    // Submit the data to the server
                    const response = await fetch('/api/submit-form-instance', {
                        method: 'POST',
                        body: formData,
                    });

                    if (!response.ok) {
                        throw new Error('Failed to submit entry to server.');
                    }

                    // If submission is successful, delete the form instance from IndexedDB
                    await deleteFormInstanceFromIndexedDB(selectedInstance.instanceId);
                    setFormInstances(formInstances.filter(inst => inst.instanceId !== selectedInstance.instanceId));
                    setSelectedInstance(null);
                    setEntryData({});
                    setValidationErrors({});
                    setImagePreviews({});
                    setManualCoordinatesFields({});
                    alert('Entry submitted successfully.');

                    if (updatePendingUploads) {
                        updatePendingUploads();
                    }
                } catch (error) {
                    console.error('Error submitting entry:', error);
                    alert('Failed to submit entry.');
                } finally {
                    setIsSubmitting(false);
                }
            } else {
                alert('Please fix the errors before submitting.');
            }
        } else {
            alert('Please select a form instance.');
        }
    };

    return (
        <div className="offline-entry-modal-overlay">
            <div className="offline-entry-modal-content">
                <button onClick={onClose} className="offline-entry-close-button">×</button>
                <h1 className="offline-entry-title">Offline Data Entry</h1>
                <div className="offline-entry-section">
                    <h2 className="offline-entry-subtitle">Create New Form Instance</h2>
                    <select
                        className="offline-entry-select"
                        onChange={(e) => handleCreateNewInstance(e.target.value)}
                    >
                        <option value="">--Select Form Template--</option>
                        {formTemplates.map((form) => (
                            <option key={form.id} value={form.id}>
                                {form.formName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="offline-entry-section">
                    <h2 className="offline-entry-subtitle">Your Form Instances</h2>
                    <ul className="offline-entry-instance-list">
                        {formInstances.map((instance) => (
                            <li key={instance.instanceId} className="offline-entry-instance-item">
                                <button
                                    onClick={() => handleSelectInstance(instance.instanceId)}
                                    className="offline-entry-instance-button"
                                >
                                    {instance.formName} ({instance.instanceName}) - {instance.status} - {new Date(instance.updatedAt).toLocaleString()}
                                </button>
                                <button
                                    onClick={() => handleDeleteInstance(instance.instanceId)}
                                    className="offline-entry-delete-button"
                                >
                                    Delete
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {selectedInstance && (
                    <div className="offline-entry-form-section">
                        <h3 className="offline-entry-form-title">
                            Filling in: {selectedInstance.formName} ({selectedInstance.instanceName})
                        </h3>
                        {selectedInstance.fields.map((field) => (
                            <div
                                key={String(field.id)}
                                className={`offline-entry-field-container`}
                            >
                                {renderField(field)}
                            </div>
                        ))}
                        <div className="offline-entry-button-group">
                            <button onClick={handleSaveEntry} className="offline-entry-save-button" disabled={isSubmitting}>
                                Save Entry
                            </button>
                            <button onClick={handleSubmitEntry} className="offline-entry-submit-button" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit Entry'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OfflineEntryDashboard;
