export async function openIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('AssetDB', 22); // Increment version to force schema update

        request.onupgradeneeded = (event) => {
            const db = event.target.result;

            // Create 'assets' object store if it doesn't exist
            if (!db.objectStoreNames.contains('assets')) {
                db.createObjectStore('assets', { keyPath: 'id', autoIncrement: true });
            }

            // Create 'forms' object store if it doesn't exist
            if (!db.objectStoreNames.contains('forms')) {
                db.createObjectStore('forms', { keyPath: 'id', autoIncrement: true });
            }

            // Create 'dropdownOptions' object store for storing dropdown options offline
            if (!db.objectStoreNames.contains('dropdownOptions')) {
                db.createObjectStore('dropdownOptions', { keyPath: 'fieldId' });
            }

            // Create 'entries' object store if it doesn't exist
            if (!db.objectStoreNames.contains('entries')) {
                db.createObjectStore('entries', { keyPath: 'id', autoIncrement: true });
            }

            // Create 'formInstances' object store if it doesn't exist
            if (!db.objectStoreNames.contains('formInstances')) {
                db.createObjectStore('formInstances', { keyPath: 'instanceId', autoIncrement: true });
            }

            // Create 'auth' object store for storing user authentication data
            if (!db.objectStoreNames.contains('auth')) {
                db.createObjectStore('auth', { keyPath: 'key' });
            }
        };

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
    });
}
