// indexedDBUtils.js

// Helper functions for IndexedDB operations
function idbRequestToPromise(request) {
    return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

export function openIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('AssetDB', 22); // Increment version if schema changes

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            const oldVersion = event.oldVersion;
            const newVersion = event.newVersion;

            console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);

            try {
                // If upgrading from a very old version, you might need to delete old object stores
                if (oldVersion < 21) {
                    // If object stores have changed, delete and recreate them
                    if (db.objectStoreNames.contains('someOldStore')) {
                        db.deleteObjectStore('someOldStore');
                    }
                    // Repeat for other stores if necessary
                }

                // Create object stores if they don't exist
                if (!db.objectStoreNames.contains('assets')) {
                    db.createObjectStore('assets', { keyPath: 'id', autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('forms')) {
                    db.createObjectStore('forms', { keyPath: 'id', autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('dropdownOptions')) {
                    db.createObjectStore('dropdownOptions', { keyPath: 'fieldId' });
                }
                if (!db.objectStoreNames.contains('entries')) {
                    db.createObjectStore('entries', { keyPath: 'id', autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('formInstances')) {
                    db.createObjectStore('formInstances', { keyPath: 'instanceId', autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('auth')) {
                    db.createObjectStore('auth', { keyPath: 'key' });
                }
            } catch (error) {
                console.error('Error during onupgradeneeded:', error);
                reject(error);
            }
        };

        request.onsuccess = (event) => resolve(event.target.result);

        request.onerror = (event) => {
            console.error('IndexedDB open error:', event.target.error);
            reject(event.target.error);
        };
    });
}

// User authentication functions
export async function saveUserToIndexedDB(user) {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('auth', 'readwrite');
        const store = transaction.objectStore('auth');
        const request = store.put({ key: 'user', value: user });
        await idbRequestToPromise(request);
    } catch (error) {
        console.error('Error saving user to IndexedDB:', error);
    }
}

export async function getUserFromIndexedDB() {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('auth', 'readonly');
        const store = transaction.objectStore('auth');
        const request = store.get('user');
        const result = await idbRequestToPromise(request);
        return result ? result.value : null;
    } catch (error) {
        console.error('Error getting user from IndexedDB:', error);
        return null;
    }
}

export async function clearUserFromIndexedDB() {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('auth', 'readwrite');
        const store = transaction.objectStore('auth');
        const request = store.delete('user');
        await idbRequestToPromise(request);
    } catch (error) {
        console.error('Error clearing user from IndexedDB:', error);
    }
}

// Form-related functions
export async function saveFormToIndexedDB(form) {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('forms', 'readwrite');
        const store = transaction.objectStore('forms');
        const request = store.put(form);
        await idbRequestToPromise(request);
    } catch (error) {
        console.error('Error saving form to IndexedDB:', error);
    }
}

export async function getFormsFromIndexedDB() {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('forms', 'readonly');
        const store = transaction.objectStore('forms');
        const request = store.getAll();
        const result = await idbRequestToPromise(request);
        return result || [];
    } catch (error) {
        console.error('Error getting forms from IndexedDB:', error);
        return [];
    }
}

export async function deleteFormFromIndexedDB(formId) {
    try {
        const db = await openIndexedDB();
        const transaction = db.transaction('forms', 'readwrite');
        const store = transaction.objectStore('forms');
        const request = store.delete(formId);
        await idbRequestToPromise(request);
    } catch (error) {
        console.error('Error deleting form from IndexedDB:', error);
    }
}

// Add other functions as needed...
